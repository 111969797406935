import React from "react";

import NavbarItem from "./navbarItem";
import UsuarioService from "../app/service/usuarioService";
import LocalStorageService from "../app/service/localStorageService";
import AuthService from "../app/service/authService";

class Navbar extends React.Component{

    state = {
        nome: '',
        email: ''
    }

    constructor(){
        super()
        this.usuarioService = new UsuarioService();
    }

    componentDidMount(){
        const usuarioLogado = LocalStorageService.obterItem('_usuario_logado')

        if (!usuarioLogado) {
            this.setState({nome: ""})
            this.setState({email: ""})
        } else {
            this.usuarioService
            .obterNomeUsuario(usuarioLogado.id)
            .then(response => {
                this.setState({nome: response.data})
            })
            this.usuarioService
            .obterEmailUsuario(usuarioLogado.id)
            .then(response => {
                this.setState({email: response.data})
            })
        } 
    }

    deslogar = () => {
        AuthService.removerUsuarioAutenticado();
    }

    render(){
        return(
            <div className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <a href="http://www.trezaco.com.br/"  className="navbar-brand">Trezaço</a>
                    <button className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarResponsive"
                            aria-controls="navbarResponsive"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav">
                            {this.state.nome &&
                            <NavbarItem href="/cotacoes" label="Cotação"/>}
                            
                            {this.state.email === "raphael@trezaco.com.br" &&
                            <NavbarItem href="/cotacoes-inativadas" label="Cotações Inativadas"/>}

                            {this.state.email === "raphael@trezaco.com.br" &&
                            <NavbarItem href="/cadastro-usuario" label="Usuários"/>}

                            {this.state.nome && 
                            <NavbarItem onClick={this.deslogar} href="/login" label="Sair"/>}
                        </ul>
                        <label id="nomeNavbar">{this.state.nome}</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;