import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import LocalStorageService from "../../app/service/localStorageService";
import UsuarioService from "../../app/service/usuarioService";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import Loading from "../../components/loading";
import { mensagemErro, mensagemSucesso } from '../../components/toastr';
import UsuariosTable from "./usuariosTable";


class CadastroUsuario extends React.Component{

    state = {
        id: '',
        nome: '',
        email: '',
        senha: '',
        senhaRepeticao: '',
        tipoUsuario: '',
        state: 'ACTIVE',
        mostrarCarregamento: false,
        mostrarTabela: false,
        arrUsuarios: [],
        atualizando : false
        
    }
    
    constructor(){
        super();
        this.service = new UsuarioService();
    }

    validar(){
        const msgs = []

        if(!this.state.nome){
            msgs.push('Preencha o campo Nome')
        }

        const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!this.state.email) msgs.push('Preencha o campo Email')
        else if(!this.state.email.toLowerCase().match(regexEmail)) msgs.push('Informe um Email válido')


        if(!this.state.senha || !this.state.senhaRepeticao) msgs.push('Digite a senha duas vezes.')
        else if(this.state.senha !== this.state.senhaRepeticao) msgs.push('As senhas precisam ser iguais.')


        if(!this.state.tipoUsuario) msgs.push('Informe o Tipo do Usuário');

        return msgs;
    }

    buscar = () => {

        this.service
            .buscarUsuarios()
            .then( resposta => {
                this.setState({ arrUsuarios: resposta.data })
                this.setState({ mostrarCarregamento : true })
                this.setState({ mostrarTabela : true})
                this.setState({ mostrarCarregamento : false })
            }).catch(error => {
                this.setState({ mostrarCarregamento : false })
                console.log(error)
            })
    }

    deletar = (usuario) => {
        this.service
            .deletar(usuario.id)
            .then(response => {
                const usuarios = this.state.arrUsuarios;
                const index = usuarios.indexOf(usuario)
                usuarios.splice(index, 1);
                this.setState(usuarios)
                mensagemSucesso('Usuário deletado com sucesso!')
                this.buscar()
            }).catch(error => {
                mensagemErro('Ocorreu um erro ao tentar deletar o usuário!')
            })
    }

    // edit = (id) => {
    //     this.setState({atualizando: true})
    //     this.props.history.push(`/cadastro-usuario/${id}`)
    //     console.log(id)
    // }

    // componentDiMount(){
    //     const params = this.props.match.params
    //     console.log('params: ', params)
    //     if(params.id){
    //         this.service.obterPorId(params.id)
    //             .then(response => {
    //                 this.setState({...response.data})
    //             }
    //             )
    //             .catch(errors => {
    //                 console.log('erro dimount')
    //             })
    //     }
    // }

    // atualizar = () => {
        
    //     //     this.mostrarCarregamento();
    //     //     const msgs = this.validar();

    //     // if(msgs && msgs.length > 0){
    //     //     msgs.forEach( (msg, index) => {
    //     //         mensagemErro(msg)
    //     //         this.removerCarregamento()
    //     //     });
    //     //     return false;
    //     // }

    //     const {id , nome ,email, senha, senhaRepeticao, tipoUsuario, state} = this.state
    //     const usuario = {id , nome ,email, senha, senhaRepeticao, tipoUsuario, state};

    //     this.service
    //         .atualizar(usuario)
    //         .then( response => {
    //             mensagemSucesso('Usuário atualizado com sucesso!')
    //             this.buscar()
    //         }).catch( error => {
    //             mensagemErro(error.response.data)
    //             this.removerCarregamento()
    //         })
    // }

    cadastrar = (e) => {

        e.preventDefault();

        setTimeout(() => {

            this.mostrarCarregamento();
            const msgs = this.validar();

        if(msgs && msgs.length > 0){
            msgs.forEach( (msg, index) => {
                mensagemErro(msg)
                this.removerCarregamento()
            });
            return false;
        }

        const usuario = {
            nome: this.state.nome,
            email: this.state.email,
            senha: this.state.senha,
            tipoUsuario: this.state.tipoUsuario,
            state: this.state.state
        }

        this.service.salvar(usuario)
            .then( response => {
                mensagemSucesso('Usuário cadastrado com sucesso!')
                this.buscar()
            }).catch( error => {
                mensagemErro(error.response.data)
                this.removerCarregamento()
            })
        }, 500);
    }

    cancelar = (e) => {
        e.preventDefault();
        this.props.history.push('/login')
    }

    setFlagTipoUsuario = ({target}) => {
        this.setState({tipoUsuario: target.value});
    }

    mostrarCarregamento = () => {
        this.setState({mostrarCarregamento: true})
    }

    removerCarregamento = () => {
        this.setState({mostrarCarregamento: false})
    }

    validUser = () => {
        const usuariologado = LocalStorageService.obterItem('_usuario_logado');
        return usuariologado.email && usuariologado.email === "raphael@trezaco.com.br";
    }

    render(){

        if(!this.validUser()) return <>Usuário sem permissão!</>

        return(
            <>
            <form>
                <div className="row">
                    <div className="col-lg-6" style={{position : 'relative', left : '300px'}} >
                        <div className="bs-component">
                            <Card title={this.state.atualizando ? "Atualização de Usuário" : "Cadastro de Usuário"}>
                                <FormGroup htmlFor="inputNome">
                                    <label>Nome: *</label>
                                    <input type="text"
                                            id="inputNome"
                                            className="form-control"
                                            name="nome"
                                            placeholder="Digite o nome do Usuário"
                                            onChange={e => this.setState({nome: e.target.value})} />
                                </FormGroup>
                                <FormGroup htmlFor="inputEmail">
                                    <label>Email: *</label>
                                    <input type="email"
                                            id="inputEmail"
                                            className="form-control"
                                            name="email"
                                            placeholder="Digite o Email do Usuário"
                                            onChange={e => this.setState({email: e.target.value})} />
                                </FormGroup>
                                <FormGroup htmlFor="inputSenha">
                                    <label>Senha: *</label>
                                    <input type="password"
                                            id="inputSenha"
                                            className="form-control"
                                            name="senha"
                                            placeholder="Digite a Senha"
                                            onChange={e => this.setState({senha: e.target.value})} />
                                </FormGroup>
                                <FormGroup htmlFor="inputRepitaSenha">
                                    <label>Repita a senha: *</label>
                                    <input type="password"
                                            id="inputRepitaSenha"
                                            className="form-control"
                                            name="repitaSenha"
                                            placeholder="Repita a senha"
                                            onChange={e => this.setState({senhaRepeticao: e.target.value})} />
                                </FormGroup>
                                <br/>
                                <FormGroup>
                                    <label>Tipo de Usuário: * </label>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" value="vendas"
                                            id="flexCheckVendas"
                                            name="tipoUsuario"
                                            onChange={this.setFlagTipoUsuario}/>
                                        <label className="form-check-label" htmlFor="flexCheckVendas">Vendas</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" value="operacoes"
                                            id="flexCheckOperacoes"
                                            name="tipoUsuario"
                                            onChange={this.setFlagTipoUsuario}/>
                                        <label className="form-check-label" htmlFor="flexCheckOperacoes">Operações</label>
                                    </div>
                                </FormGroup>
                            </Card>
                            <div className="group-button-cadastro">
                                {/* {this.state.atualizando ?
                                    (
                                        <button onClick={this.atualizar} className="btn btn-success">Atualizar</button>
                                    ) : ( */}
                                        <button onClick={this.cadastrar} className="btn btn-success">Salvar</button>
                                    {/* )

                                } */}
                                <button onClick={this.cancelar}  className="btn btn-primary">Cancelar</button>
                                <button onClick={this.buscar} type="button" className="btn btn-info">Exibir Usuários</button>
                            </div>
                            <br/><br/>
                            {this.state.mostrarCarregamento === true &&
                                <Loading />
                            }
                        </div>
                    </div>
                </div>
            </form>

            <div className="row">
                <div className="col-md-12">
                    <div className="bs-component scroll-horizontal">
                        {this.state.mostrarTabela && (
                            <UsuariosTable
                                usuarios={this.state.arrUsuarios}
                                //deleteAction={abrirConfirmacao}
                                deleteAction={this.deletar}
                                editAction={this.edit}
                            />
                        )}

                        {/* {showLoadingModal && <Loading />}
                        {!showLoadingModal && arrUsuarios.length === 0 &&(
                            <p>Não há usuários cadastrados!</p>
                        )} */}
                    </div>
                </div>
            </div>

            </>

            
        )
    }
}

export default withRouter(CadastroUsuario);