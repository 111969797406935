import ApiService from "../apiservice";

class ProdutoService extends ApiService{

    constructor(){
        super('/api/produtos')
    }


    buscarProdutos() {
        return this.get('');
    }

}

export default ProdutoService;