import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { IoConstructOutline, IoSaveSharp } from "react-icons/io5";
import ClienteService from "../../app/service/clienteService";
import CotacaoService from "../../app/service/cotacaoService";
import LocalStorageService from "../../app/service/localStorageService";
import ProdutoService from "../../app/service/produtoService";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import FormSelect from "../../components/form-select";
import Loading from "../../components/loading";
import * as messages from "../../components/toastr";
import { mensagemAlerta } from "../../components/toastr";
import CotacoesTable from "./cotacoesTable";
import CotacoesTableProduto from "./cotacoesTableProduto";
import {findDOMNode} from "react-dom";


const Cotacoes = () => {

    const usuarioLogado = LocalStorageService.obterItem("_usuario_logado");

    const service = new CotacaoService();
    const clienteService = new ClienteService();
    const produtoService = new ProdutoService();

    const [arrCotacaoProduto, setArrCotacaoProduto] = React.useState([]);
    const [arrCliente, setArrCliente] = React.useState([]);
    const [arrProduto, setArrProduto] = React.useState([]);
    const [cotacaoDeletar, setCotacaoDeletar] = React.useState();
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [showModalUpdatePrice, setShowModalUpdatePrice] = React.useState(false);
    const [showModalHistoricoCotacoes, setShowModalHistoricoCotacoes] = React.useState(false);
    const [showLoadingModal, setShowLoadingModal] = React.useState(false)
    const [refreshTable, setRefreshTable] = React.useState(false);

    const [listaUnidade, setListaUnidade] = React.useState([
        { id: "Metro", nome: "Metro" },
        { id: "Kg", nome: "Kg" },
        { id: "Peça", nome: "Peça" },
    ]);

    const [dadosCotacao, setDadosCotacao] = React.useState({
        usuario: usuarioLogado && usuarioLogado.id,
        state: "ACTIVE",
        cliente: "",
        produto: "",
        unidade: "",
        preco: "",
        sugestao: "",
    });

    React.useEffect(() => {
        buscarClientes();
        buscarProdutos();
        buscarCotacoesProdutos();
    }, []);

    
    async function buscarCotacoesProdutos(cotacao){
        try {
            setShowLoadingModal(true);
            const idProduto = cotacao && cotacao.produto && cotacao.produto.id;
            if (!idProduto) return;
            const r = await service.consultarPorProduto(idProduto);
            const arrCotacaoFiltro = r.data.filter(c => c.produto.id === idProduto);
            setArrCotacaoProduto(arrCotacaoFiltro);
        }
        catch(e) {
        }
        finally {
            setShowLoadingModal(false);
        }
    }


    async function buscarClientes() {
        try {
            setShowLoadingModal(true);
            const r = await clienteService.buscarClientes();
            setArrCliente(r.data);
        }catch(e){
        }
        finally {
            setShowLoadingModal(false);
        }
    }


    async function buscarProdutos() {
        try {
            setShowLoadingModal(true);
            const r = await produtoService.buscarProdutos();
            setArrProduto(r.data);
        }catch (e){}
        finally {
            setShowLoadingModal(false);
        }

    }


    const abrirConfirmacao = (cotacao) => {
        setShowModalDelete(true);
        setCotacaoDeletar(cotacao);
    };


    const confirmDialogFooter = (
        <div>
            <Button label="Confirma" icon="pi pi-check" onClick={deletar} />
            <Button label="Cancela" icon="pi pi-times" onClick={cancelarDelecao} />
        </div>
    );

    const confirmDialogFooterUpdate = (
        <>
            <Button label="Atualizar" icon="pi pi-check" onClick={updatePreco} />
            <Button label="Cancela" icon="pi pi-times" onClick={() => setShowModalUpdatePrice(false)} />
        </>
    );

    const dialogHistoricoCotacoes = (
        <>
            <Button label="Fechar" icon="pi pi-times" onClick={() => setShowModalHistoricoCotacoes(false)} />
        </>
    );


    async function deletar() {
        let cotacao_deletar_id = cotacaoDeletar.id;

        try {
            const r = await service.deletar(cotacao_deletar_id);
            messages.mensagemSucesso("Deletado com sucesso");
        } catch (e) {
            messages.mensagemErro("Ocorreu um erro ao tentar deletar");
        } finally {
            setShowModalDelete(false);
            setRefreshTable(true);
            setRefreshTable(false);
        }
    }


    function cancelarDelecao() {
        setShowModalDelete(false);
        setCotacaoDeletar({});
    }


    function preencheDadosCotacao({ target }) {
        const { id, value } = target;
        setDadosCotacao({ ...dadosCotacao, [id]: value });
    }

    function preencheDadosCotacaoSelect({ target }) {
        const { id, dataset } = target;
        setDadosCotacao({ ...dadosCotacao, [id]: dataset.realvalue });
    }


    function camposPreenchidos() {
        let camposPreenchidos = true;

        let {cliente, produto, quantidade, unidade, usuario} = dadosCotacao;

        if(!cliente || cliente === 'undefined') camposPreenchidos = false;
        if(!produto || produto === 'undefined') camposPreenchidos = false;
        if(!quantidade) camposPreenchidos = false;
        if(!unidade || unidade === 'undefined') camposPreenchidos = false;
        if(!usuario) camposPreenchidos = false;
        if(!camposPreenchidos) mensagemAlerta("Preencha todos os dados pra criar uma cotação");

        return camposPreenchidos;
    }


    async function salvar() {
        try {
            if(!camposPreenchidos()) return;

            await service.salvar(dadosCotacao);

            setRefreshTable(true);
            setRefreshTable(false);
        }
        catch (e) {}
        finally {

        }
    }

    function editar (cotacao) {
        setDadosCotacao(cotacao);
        setShowModalUpdatePrice(true);
    }

    function camposPreenchidosAtualizarCotacao() {
        let camposPreenchidosAtualizarCotacao = true;

        let {preco} = dadosCotacao;
        if(!preco) camposPreenchidosAtualizarCotacao = false;

        if(!camposPreenchidosAtualizarCotacao) mensagemAlerta("É necessário informar o Preço")

        return camposPreenchidosAtualizarCotacao;
    }

    async function updatePreco() {
        try {
            setShowLoadingModal(true);
            if(!camposPreenchidosAtualizarCotacao()) return;

            await service.atualizar(dadosCotacao);
            setShowModalUpdatePrice(false);
            messages.mensagemSucesso("Atualizado com sucesso");
        }
        catch (e) {
            messages.mensagemErro("Ocorreu um erro ao tentar atualizar");
        }
        finally {
            setShowLoadingModal(false);
            setRefreshTable(true);
            setRefreshTable(false);
        }
    }


    return (
        <>
        <Card title="Cotação">
            { usuarioLogado && usuarioLogado.tipoUsuario === "vendas" &&
                <div className="row">
                    <div className="col-lg-12">
                        <div className="bs-component">
                            <form>
                                <fieldset>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <FormGroup htmlFor="cliente" Label="Cliente: ">
                                                <FormSelect
                                                    id="cliente"
                                                    value={dadosCotacao["cliente"]}
                                                    onChange={preencheDadosCotacaoSelect}
                                                    className="form-control"
                                                    lista={arrCliente}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormGroup htmlFor="produto" Label="Produto: ">
                                                <FormSelect
                                                    id="produto"
                                                    value={dadosCotacao["produto"]}
                                                    onChange={preencheDadosCotacaoSelect}
                                                    className="form-control"
                                                    lista={arrProduto}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-1">
                                            <FormGroup htmlFor="quantidade" Label="Quantidade: ">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="1"
                                                    id="quantidade"
                                                    placeholder="Ex: 2"
                                                    value={dadosCotacao["quantidade"] || ""}
                                                    onChange={preencheDadosCotacao}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-2">
                                            <FormGroup htmlFor="unidade" Label="Unidade: ">
                                                <FormSelect
                                                    id="unidade"
                                                    value={dadosCotacao["unidade"]}
                                                    onChange={preencheDadosCotacaoSelect}
                                                    className="form-control"
                                                    lista={listaUnidade}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className="col-lg-1">
                                            <button
                                                id="buttonCotacaoSalvar"
                                                type="button"
                                                onClick={salvar}
                                                className="btn btn-success"
                                            >
                                                <IoSaveSharp /> Salvar
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-md-12">
                    <div className="bs-component scroll-horizontal">
                        <CotacoesTable
                            funcHistoricoProduto={buscarCotacoesProdutos}
                            deleteAction={abrirConfirmacao}
                            editAction={editar}
                            setShowModalHistoricoCotacoes={setShowModalHistoricoCotacoes}
                            usuarioLogado={usuarioLogado}
                            cotacoesInativadas={false}
                            refreshTable={refreshTable}
                        />
                    </div>
                </div>
            </div>

            <div>
                <Dialog
                    header="Confirmação"
                    visible={showModalDelete}
                    style={{ width: "50vw" }}
                    modal={true}
                    footer={confirmDialogFooter}
                    onHide={() => setShowModalDelete(false)}
                >
                    Confirma a exclusão deste item?
                    {showLoadingModal && <Loading />}
                </Dialog>
            </div>

            <div>
                <Dialog
                    header="Atualizar Cotações"
                    visible={showModalUpdatePrice}
                    style={{ width: "50vw" }}
                    modal={true}
                    footer={confirmDialogFooterUpdate}
                    onHide={() => setShowModalUpdatePrice(false)}
                >
                    {!showLoadingModal &&
                        <>
                        <label htmlFor="preco">Preço: </label>
                        <input
                            type="number"
                            className="form-control"
                            id="preco"
                            value={dadosCotacao["preco"] || ''}
                            onChange={preencheDadosCotacao}
                        />
                        <br/>
                        <label htmlFor="sugestao">Sugestão: </label>
                        <input
                            type="text"
                            className="form-control"
                            id="sugestao"
                            value={dadosCotacao["sugestao"] || ''}
                            onChange={preencheDadosCotacao}
                        />
                        </>
                    }
                    {showLoadingModal && <Loading />}
                </Dialog>
            </div>


            <div>
                <Dialog
                    header={"Historico de Cotações" + (showLoadingModal ? " | Carregando..." : "")}
                    visible={showModalHistoricoCotacoes}
                    style={{ width: "50vw" }}
                    modal={true}
                    footer={dialogHistoricoCotacoes}
                    onHide={() => setShowModalHistoricoCotacoes(false)}
                >
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bs-component">
                                {showLoadingModal && <Loading />}
                                {!showLoadingModal && arrCotacaoProduto && (
                                    <CotacoesTableProduto
                                        cotacoes={arrCotacaoProduto}
                                    />
                                )}
                                {!showLoadingModal && arrCotacaoProduto.length === 0 &&(
                                    <p>Não há cotações cadastradas!</p>
                                )}
                            </div>
                        </div>
                    </div>
                            

                </Dialog>
            </div>

        </Card>
        </>
    );
};

export default Cotacoes;
