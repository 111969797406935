import React from "react";

import Login from "../views/login";
import CadastroUsuario from "../views//usuarios/cadastroUsuario";
import Cotacoes from "../views/cotacoes/cotacoes";
import Home from "../views/home";
import CotacoesInativadas from "../views/cotacoes/cotacoesInativadas";

import { BrowserRouter, Route, Switch } from 'react-router-dom';

function Rotas(){
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/"  exact component={Login}/>
                <Route path="/login" component={Login}/>
                <Route path="/cadastro-usuario/" component={CadastroUsuario}/>
                <Route path="/cotacoes" component={Cotacoes}/>
                <Route path="/home" component={Home}/>
                <Route path="/cotacoes-inativadas" component={CotacoesInativadas}/>
            </Switch>
        </BrowserRouter>
    )
    
}

export default Rotas;