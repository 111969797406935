import React from "react";

import '../custom.css';
import 'bootswatch/dist/simplex/bootstrap.css';
import 'toastr/build/toastr.css'
import 'toastr/build/toastr.min.js'
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

import Rotas from "./rotas";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

//Bootstrap and jQuery libraries
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import jQuery from 'jquery';

class App extends React.Component {
  
  render(){

    return (
      <>
        <Navbar />
        <div className="container" style={{flexGrow: '1', paddingTop: '20px'}}>
          <Rotas />
        </div>
        <Footer />
      </>
    )
  }
  
}

export default App;
