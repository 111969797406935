import ApiService from "../apiservice";
import LocalStorageService from "../../app/service/localStorageService";

export default class CotacaoService extends ApiService{

    constructor(){
        super('/api/cotacoes')
    }

    consultar(page, size, stateCotacao){
        const usuarioLogado = LocalStorageService.obterItem('_usuario_logado');
        let params = `?usuario=${usuarioLogado.id}&page=${page}&size=${size}&state=${stateCotacao}`
        return this.get(params);
    }

    consultarPorProduto(idProduto){
        return this.get('/produtos?produto=' + idProduto);
    }

    salvar(cotacao){
        return this.post('', cotacao);
    }

    atualizar(cotacao) {
        cotacao = {
            ...cotacao,
            "cliente": cotacao.cliente.id,
            "produto": cotacao.produto.id,
            "usuario": cotacao.usuario.id,
        }
        return this.put('/' + cotacao.id, cotacao);
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

    getTotalRows(idUsuario, state) {
        return this.get(`/totalRows?usuario=${idUsuario}&state=${state}`);
    }

}
