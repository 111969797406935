import ApiService from "../apiservice";

class UsuarioService extends ApiService{

    constructor(){
        super('/api/usuarios')
    }

    autenticar(credenciais){
        return this.post('/autenticar', credenciais)
    }

    obterNomeUsuario(id){
        return this.get(`/${id}/nome`)
    }

    obterEmailUsuario(id){
        return this.get(`/${id}/email`)
    }

    obeterPorId(id){
        return this.get(`/${id}`)
    }

    salvar(usuario){
        return this.post('/', usuario);
    }

    buscarUsuarios() {
        return this.get('');
    }

    atualizar(usuario) {
        return this.put(`/${usuario.id}}`, usuario);
    }

    deletar(id){
        return this.delete(`/${id}`)
    }

}

export default UsuarioService;