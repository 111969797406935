import React from "react";

function FormSelect(props){

    const input = React.useRef(null);


    if(!props.lista) return;
    const listItems = props.lista.sort((a, b) => (a.nome > b.nome) ? 1 : -1);


    const options = listItems.map((option, index) => {
        return(
            <option key={index} value={option.nome}>{option.id}</option>
        )
    })


    const fillDataRealValue = (e) => {
        const obj = listItems.find(item => item.nome === input.current.value);
        if(obj){
            input.current.dataset.realvalue = obj && obj.id;
            props.onChange(e);
        }
    }


    return (
        <>
            <input id={props.id} ref={input} onChange={fillDataRealValue} data-realvalue="" list={props.id + "_list"} className="form-control" placeholder="Digite aqui..."/>
            <datalist id={props.id + "_list"}>
                {options}
            </datalist>
        </>
    );
}

export default FormSelect;