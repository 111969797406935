import currencyFormatter from "currency-formatter";
import React, {useEffect, useState} from "react";
import { FaList, FaTrashAlt } from "react-icons/fa";
import { HiOutlinePencilAlt } from "react-icons/hi";
import DataTable from 'react-data-table-component';
import CotacaoService from "../../app/service/cotacaoService";
import LocalStorageService from "../../app/service/localStorageService";


function CotacoesTable({editAction, deleteAction, usuarioLogado, setShowModalHistoricoCotacoes, funcHistoricoProduto, cotacoesInativadas, refreshTable}) {

    const service = new CotacaoService();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [sizePage, setSizePage] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => {
        fetchCotacoes(page);
    }, []);

    useEffect(() => {
        fetchCotacoes(page, sizePage);
    }, [page, sizePage, refreshTable]);

    const fetchCotacoes = async (page) => {
        setLoading(true);

        let r = await service.consultar(page, sizePage, cotacoesInativadas);

        const dataArray = r.data;
        const data = dataArray.map(cotacao => {
            return {
                id: cotacao.id,
                cliente: cotacao.cliente && cotacao.cliente.nome,
                produto: cotacao.produto && cotacao.produto.nome,
                unidade: cotacao.unidade,
                quantidade: cotacao.quantidade,
                sugestao: cotacao.sugestao,
                preco: currencyFormatter.format(cotacao.preco, {locale: 'pt-BR'}),
                dataDeCotacao: cotacao.dataDeCotacao,
                dataDeRespostaCotacao: cotacao.dataDeRespostaCotacao,
                vendedor: cotacao.usuario && cotacao.usuario.nome,
                acoes: columnAcoes(cotacao),
            }
        });
        setData(data);

        const usuarioLogado = LocalStorageService.obterItem("_usuario_logado")
        const idUsuarioLogado = usuarioLogado.id;
        const rGetTotalRows = await service.getTotalRows(idUsuarioLogado, cotacoesInativadas);
        const getTotalRows = rGetTotalRows.data;
        setTotalRows(getTotalRows);

        setLoading(false);
    }

    const handlePerRowsChange = async (newPerPage) => {
        setSizePage(newPerPage);
    }

    const handlePageChange = page => {
        setPage(page);
    }


    const columns = [
        {name: 'Cliente', selector: row => row.cliente, wrap:true},
        {name: 'Produto', selector: row => row.produto, wrap:true},
        {name: 'Unidade', selector: row => row.unidade},
        {name: 'Quantidade', selector: row => row.quantidade},
        {name: 'Sugestão', selector: row => row.sugestao, wrap:true},
        {name: 'Preço', selector: row => row.preco},
        {name: 'Data de Cotação', selector: row => row.dataDeCotacao, wrap:true},
        {name: 'Data de Resposta', selector: row => row.dataDeRespostaCotacao, wrap:true},
        {name: 'Vendedor', selector: row => row.vendedor, wrap:true},
        {name: 'Ações', selector: row => row.acoes, width: cotacoesInativadas ? "0" : "auto"},
    ];

    const columnAcoes = (cotacao) => {

        if(cotacoesInativadas) return;

        if(usuarioLogado && usuarioLogado.tipoUsuario === "operacoes") {

            const ButtonHistory = () => {
                return (<button type="button"
                                className="btn btn-info btn-table"
                                onClick={() => {
                                    setShowModalHistoricoCotacoes(true);
                                    funcHistoricoProduto(cotacao);
                                }}
                                title="Histórico de Cotações do Produto">
                            <FaList/>
                    </button>
                );
            }

            const ButtonEdit = () => {
                return (<button type="button"
                                className="btn btn-warning btn-table"
                                onClick={e => editAction(cotacao)}
                                title="Atualizar preço da cotação">
                            <HiOutlinePencilAlt/>
                        </button>
                );
            };

            return <><ButtonHistory/><ButtonEdit/></>;
        }
        else {
            return <button type="button" className="btn btn-primary btn-table" onClick={e => deleteAction(cotacao)}
                    title="Deletar cotação"><FaTrashAlt/></button>
        }
    }


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            }
        }
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Cotações por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                pagination
                striped
                highlightOnHover
                subHeaderWrap
                customStyles={customStyles}
                progressPending={loading}
                paginationServer
                paginationComponentOptions={paginationComponentOptions}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </>
    )
}

export default CotacoesTable
