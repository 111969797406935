import React from "react";
import styles from './loading.module.css'
import loading from '../img/Double Ring-1s-200px.svg'

function Loading(){
    return(
        <div className={styles.loader_container}>
            <img className={styles.loader} src={loading} alt="Loading" />
        </div>
    )
}

export default Loading;