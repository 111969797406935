import React from "react";
import Card from '../components/card';
import FormGroup from "../components/form-group";
import { withRouter } from 'react-router-dom';
import UsuarioService from "../app/service/usuarioService";
import LocalStorageService from "../app/service/localStorageService";
import {mensagemErro, mensagemAlerta, mensagemSucesso} from '../components/toastr'
import Loading from "../components/loading";

class Login extends React.Component{

    state = {
        email: '',
        senha: '',
        mostrarCarregamento: false
    }

    constructor(){
        super();
        this.service = new UsuarioService();
    }

    camposPreenchidos() {

        const {email, senha} = this.state;

        let campos_preenchidos = true;

        if(!email) campos_preenchidos = false;
        if(!senha) campos_preenchidos = false;

        if(!campos_preenchidos) mensagemAlerta("Preencha todos os campos");

        return campos_preenchidos;
    }

    entrar = (e) => {

        e.preventDefault();

        if(!this.camposPreenchidos()) return;

        this.mostrarCarregamento();

        setTimeout(() => {
            this.service.autenticar({
                email: this.state.email,
                senha: this.state.senha
            }).then(response => {
                LocalStorageService.adicionarItem('_usuario_logado', response.data)
                // this.props.history.push('/cotacoes')
                mensagemSucesso("Autenticado com sucesso");
                window.location.href = "/cotacoes";

            }).catch(erro => {
                mensagemErro(erro.response.data)

            }).finally(f => {
                this.removerCarregamento();
            })
        }, 500);
    }

    cadastrar = () => {
        this.props.history.push('/cadastro-usuario')
    }

    mostrarCarregamento = () => {
        this.setState({mostrarCarregamento: true})
    }

    removerCarregamento = () => {
        this.setState({mostrarCarregamento: false})
    }

    render(){
        return (
            <>
            <form>
                <div className="row">
                    <div className="col-md-6" style={{position : 'relative', left : '300px'}}>
                        <div className="bs-docs-section">
                            <Card title="Login">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="bs-component">
                                            <fieldset>
                                                <FormGroup htmlFor="email">
                                                    <label>Email: *</label>
                                                    <input type="email"
                                                        value={this.state.email}
                                                        onChange={e => this.setState({email: e.target.value})}
                                                        className="form-control"
                                                        id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Digite o Email" />
                                                </FormGroup>
                                                <FormGroup htmlFor="password">
                                                    <label>Senha: *</label>
                                                    <input type="password"
                                                        value={this.state.senha}
                                                        onChange={e => this.setState({senha: e.target.value})}
                                                        className="form-control"
                                                        id="exampleInputPassword1"
                                                        placeholder="Password"/>
                                                </FormGroup>
                                                <br/>
                                                {!this.state.mostrarCarregamento &&
                                                    <div className="group-button-login">
                                                        <button onClick={this.entrar} className="btn btn-success">Entrar</button>
                                                        {/* {
                                                            this.state.email === "raphael@trezaco.com.br" &&
                                                            <button onClick={this.cadastrar} className="btn btn-primary">Cadastrar</button>
                                                        } */}
                                                    </div>
                                                }
                                                {this.state.mostrarCarregamento && <Loading />}
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </form>
            </>
        )    
    }
}

export default withRouter( Login );