import currencyFormatter from "currency-formatter";
import React from "react";

function CotacoesTableProduto({cotacoes}){

    const rows = cotacoes.map(cotacao => {
        return (
            <tr key={cotacao.id}>
                <td>{cotacao.cliente && cotacao.cliente.nome}</td>
                <td>{cotacao.produto && cotacao.produto.nome}</td>
                <td>{cotacao.quantidade}</td>
                <td>{currencyFormatter.format(cotacao.preco, {locale: 'pt-BR'})}</td>
            </tr>
        )
    })

    return (
        <table className="table table-hover">
            <thead>
                <tr>
                    <th scope="col">Cliente</th>
                    <th scope="col">Produto</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Preço</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table> 
    )
}

export default CotacoesTableProduto
