import axios from "axios";

const httpCLient = axios.create({
    // baseURL: 'http://localhost:8080'
    // baseURL: 'https://trezacoapi.herokuapp.com'
    // baseURL: 'https://trezaco.herokuapp.com'
    baseURL: 'https://api-trezaco.herokuapp.com'
})

class ApiService {

    constructor(apiurl){
        this.apiurl = apiurl;
    }

    post(url, objeto) {
        const requestUrl = `${this.apiurl}${url}`
        return httpCLient.post(requestUrl, objeto)
    }

    put(url, objeto) {
        const requestUrl = `${this.apiurl}${url}`
        return httpCLient.put(requestUrl, objeto)
    }

    delete(url) {
        const requestUrl = `${this.apiurl}${url}`
        return httpCLient.delete(requestUrl)
    }

    get(url) {
        const requestUrl = `${this.apiurl}${url}`
        return httpCLient.get(requestUrl)
    }

}

export default ApiService;
