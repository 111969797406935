import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { IoSaveSharp } from "react-icons/io5";
import ClienteService from "../../app/service/clienteService";
import CotacaoService from "../../app/service/cotacaoService";
import LocalStorageService from "../../app/service/localStorageService";
import ProdutoService from "../../app/service/produtoService";
import Card from "../../components/card";
import FormGroup from "../../components/form-group";
import FormSelect from "../../components/form-select";
import Loading from "../../components/loading";
import * as messages from "../../components/toastr";
import { mensagemAlerta } from "../../components/toastr";
import CotacoesTable from "./cotacoesTable";
import CotacoesTableProduto from "./cotacoesTableProduto";


const CotacoesInativadas = () => {

    const usuarioLogado = LocalStorageService.obterItem("_usuario_logado");

    const service = new CotacaoService();

    const [arrCotacaoProduto, setArrCotacaoProduto] = React.useState([]);
    const [showModalHistoricoCotacoes, setShowModalHistoricoCotacoes] = React.useState(false);
    const [showLoadingModal, setShowLoadingModal] = React.useState(false)

    React.useEffect(() => {
        buscarCotacoesProdutos();
    }, []);

    async function buscarCotacoesProdutos(cotacao){

        const idProduto = cotacao && cotacao.produto && cotacao.produto.id;
        if(!idProduto) return;

            setShowLoadingModal(true);
            const r = await service.consultar();
            const arrCotacaoFiltro = r.data.filter(c => c.produto.id === idProduto);
            setArrCotacaoProduto(arrCotacaoFiltro);
            setShowLoadingModal(false);
    }

    const dialogHistoricoCotacoes = (
        <>
            <Button label="Fechar" icon="pi pi-times" onClick={() => setShowModalHistoricoCotacoes(false)} />
        </>
    );

    return (
        <>
        <Card title="Cotações Inativadas">
            <div className="row">
                <div className="col-md-12">
                    <div className="bs-component scroll-horizontal">
                            <CotacoesTable
                                usuarioLogado={usuarioLogado}
                                cotacoesInativadas={true}
                            />
                    </div>
                </div>
            </div>

        </Card>
        </>
    );
};

export default CotacoesInativadas;
