import ApiService from "../apiservice";

class ClienteService extends ApiService{

    constructor(){
        super('/api/clientes')
    }

    obterNomeCliente(id){
        return this.get(`/${id}/nome`)
    }

    buscarClientes() {
        return this.get('');
    }

}

export default ClienteService;