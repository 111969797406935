import React from "react";

import UsuarioService from "../app/service/usuarioService";
import LocalStorageService from "../app/service/localStorageService";

class Home extends React.Component{
    
    state = {
        nome: '',
    }

    constructor(){
        super()
        this.usuarioService = new UsuarioService();
    }

    componentDidMount(){
        const usuarioLogado = LocalStorageService.obterItem('_usuario_logado')

        if (!usuarioLogado) {
            this.setState({nome: ""})
        } else {
            this.usuarioService
            .obterNomeUsuario(usuarioLogado.id)
            .then(response => {
                this.setState({nome: response.data})
            })
        }
    }

    render(){
        return(
            <h1>{"Bem vindo " + this.state.nome}</h1>
        )
    }
}

export default Home;