import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { HiOutlinePencilAlt } from "react-icons/hi";

export default props => {
//function UsuariosTable({usuarios, editAction, deleteAction}){
    
    const rows = props.usuarios.map(usuario => {

        return (
            <tr key={usuario.id}>
                <td>{usuario.nome}</td>
                <td>{usuario.email}</td>
                <td>{usuario.senha}</td>
                <td>{usuario.tipoUsuario}</td>
                <td>{usuario.state}</td>
                <td className="nowrap">
                    {/* <button type="button"
                            className="btn btn-warning btn-table"
                            onClick={e => props.editAction(usuario.id)}
                            title="Atualizar Usuário"
                        ><HiOutlinePencilAlt />
                    </button> */}
                    <>
                        {usuario.state === "ACTIVE" && 
                        <button type="button"
                                className="btn btn-primary btn-table"
                                onClick={e => props.deleteAction(usuario)}
                                title="Deletar Usuário">
                            <FaTrashAlt/>
                        </button> }
                        {/* <button type="button"
                                className="btn btn-warning btn-table"
                                onClick={e => props.editAction(usuario)}
                                title="Atualizar informações do usuário"
                            ><HiOutlinePencilAlt />
                        </button> */}
                    </>
                </td>
            </tr>
        )
    })

    return (
        <table className="table table-hover dot8rem">
            <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Email</th>
                    <th scope="col">Senha</th>
                    <th scope="col">Tipo Usuário</th>
                    <th scope="col">Situação</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table> 
    )
}

//export default UsuariosTable
