import React from "react";

function Footer(){
    return(
        <div className="footer-dark">
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-3 item">
                            <h3>Contatos</h3>
                            <ul>
                                <li><a href="mailto:nextevolutionsn@outlook.com">nextevolutionsn@outlook.com</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 item text text-align-center">
                            <h3>Next Evolution</h3>
                            <p>Todos os direitos reservados - Next Evolution.</p>
                        </div>
                    </div>
                    <p className="copyright">Trezaço © 2021</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer;